import { Icon } from "@mui/material";
import SuiPagination from "components/SuiPagination";
import React from "react";
import { usePagination, DOTS } from "./PaginationRange";

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <SuiPagination>
      <SuiPagination item onClick={onPrevious} disabled={currentPage === 1 ? true : false}>
        <Icon>keyboard_arrow_left</Icon>
      </SuiPagination>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <SuiPagination key={index} item>
              &#8230;
            </SuiPagination>
          );
        }

        // Render our Page Pills
        return (
          <SuiPagination
            item
            active={pageNumber === currentPage ? true : false}
            onClick={() => onPageChange(pageNumber)}
            key={index}
          >
            {pageNumber}
          </SuiPagination>
        );
      })}
      <SuiPagination item onClick={onNext} disabled={currentPage === lastPage ? true : false}>
        <Icon>keyboard_arrow_right</Icon>
      </SuiPagination>
    </SuiPagination>
  );
};

export default Pagination;
