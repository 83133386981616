import SuiInput from "components/SuiInput";
import { Controller } from "react-hook-form";

function ControlledInputField({ placeholder, type, control, name, errors }) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={""}
        render={({ field }) => (
          <SuiInput
            {...field}
            type={type}
            placeholder={placeholder}
            className={errors[name] && " error-border"}
          />
        )}
      />
      {errors && <div className="error-msg">{errors[name]?.message}</div>}
    </>
  );
}
export default ControlledInputField;
