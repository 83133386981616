import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import Document from "examples/Icons/Document";
import Reports from "layouts/reports";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    route: "/",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
    privacy: "private",
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: "/reports",
    icon: <Document size="12px" />,
    component: <Reports />,
    noCollapse: true,
    privacy: "private",
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   route: "/tables",
  //   icon: <Office size="12px" />,
  //   component: <Tables />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <CreditCard size="12px" />,
  //   component: <Billing />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  // { type: "title", title: "Account Pages", key: "account-pages" },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <Profile />,
  //   noCollapse: true,
  //   privacy: "private",
  // },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <CustomerSupport size="12px" />,
    component: <SignIn />,
    noCollapse: false,
    privacy: "public",
  },
  // {
  //   type: "",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   route: "/authentication/sign-up",
  //   icon: <CustomerSupport size="12px" />,
  //   component: <SignUp />,
  //   noCollapse: false,
  //   privacy: "public",
  // },
];

export default routes;
