export const TIME_FORMAT = "DD-MM-YYYY hh:mm A";
export const ATTENDANCE_FILTERS = {
  all: "all",
  checked_in_office: "checked_in_office",
  checked_in_wfh: "checked_in_wfh",
  on_break: "on_break",
  checked_out: "checked_out",
  absent: "absent",
};
export const THEME_COLORS = {
  primary: "#18C1E8",
  secondary: "#9825B9",
  secondary_light: "#DADADA40",
  black: "#000000",
  white: "#ffffff",
};
export const CHECKED_IN_TYPES = {
  office: "Office",
  wfh: "WFH",
};
export const NOTIFICATION_MESSAGES = [
  "Hey there! Just a friendly reminder to take a deep breath and check in with yourself. How are you feeling right now?",
  "Time for a mental health check-in! Make sure to give yourself some love and attention today.",
  "Don't forget to pause and reflect on your goals. You're doing great, but a little check-in can keep you on track!",
  "It's check-in o'clock! Take a moment to appreciate your progress and celebrate the small victories.",
  "Remember, you're a superhero in disguise! Take a break and check in with your inner powers.",
  "Life can be a rollercoaster; take a moment to check-in with your emotions and embrace the ride.",
  "Pause, breathe, and reset. It's time for a mindfulness check-in to find your center.",
  "Hey, superstar! Time to check in with your dreams and see if they need a little boost.",
  "You're a rockstar, but even rockstars need a breather. Take five minutes to check in and recharge.",
  "Just a gentle nudge to check in with your body. Listen to what it needs, and treat it kindly.",
  "Don't forget to check in with your passions today. Pursue what sets your soul on fire!",
  "It's check-in time! Show yourself some compassion and remember, it's okay to ask for help.",
  "You're a work of art in progress! Take a moment to check in and appreciate your journey.",
  "Time to check in with your tribe. Reach out to someone you care about and spread some love.",
  "You're a shining star, but even stars need rest. Take a moment to check in and recharge.",
  "It's check-in o'clock! Reflect on your accomplishments and set intentions for the rest of the day.",
  "Pause for a moment and check in with your thoughts. Remember, you have the power to change them.",
  "Just a reminder to check in with your self-care routine. Treat yourself like the VIP you are!",
  "Hey, you amazing human! Take a break and check in with your heart. What makes it sing?",
  "You've got this! But even superheroes need a little check-in to save the day.",
];
