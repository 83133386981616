import SuiAlert from "../SuiAlert";

function ErrorAlert({ color, message, clearError }) {
  return (
    <div className="error-alert-wrapper z-10 w-100" style={{ position: "fixed" }}>
      <SuiAlert color={color} clearError={clearError}>
        {message}
      </SuiAlert>
    </div>
  );
}

export default ErrorAlert;
