import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ children, ...rest }) => {
  const { isLoggedIn, token } = useSelector((state) => state.auth);

  if (isLoggedIn && token) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export default PublicRoute;
