import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { AuthSlice } from "layouts/authentication/auth.reducer";

const reducers = combineReducers({
  auth: AuthSlice.reducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "dashboard"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
