import SuiAvatar from "components/SuiAvatar";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Author(props) {
  return (
    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <SuiBox mr={2}>
        <SuiAvatar src={props.image} alt={props.name} size="sm" variant="rounded" bgColor="light" />
      </SuiBox>
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="button" fontWeight="medium">
          {props.name}
        </SuiTypography>
        <SuiTypography variant="caption" color="secondary">
          {props.email}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default Author;
