import axios from "axios";
import { store } from "store/store";
import appConfig from "../../config";
import errorResponseHandler from "./errorHandler";
import successResponseHandler from "./successHandler";

export const NetworkConfig = () => {
  axios.defaults.baseURL = appConfig.baseUrl;
  axios.defaults.timeout = 30000;
  axios.defaults.headers.get["Accept"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";

  axios.interceptors.request.use(async (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = token;
    } else {
      delete config.headers.Authorization;
    }
    return config;
  });
  axios.interceptors.response.use(successResponseHandler, errorResponseHandler);
};
