import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function PageNotFound() {
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <h1 className="text-center">Error 404, Route not found</h1>
        </DashboardLayout>
    );
}

export default PageNotFound;