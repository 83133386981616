import { ATTENDANCE_FILTERS } from "constants";

export const DASHBOARD_CARDS = [
  {
    name: "total_users",
    text: "All",
    filterBy: ATTENDANCE_FILTERS.all,
    iconColor: "info",
    iconComponent: "group",
  },
  {
    name: "total_checked_in_office",
    text: "Checked In (Office)",
    filterBy: ATTENDANCE_FILTERS.checked_in_office,
    iconColor: "success",
    iconComponent: "login",
  },
  {
    name: "total_checked_in_wfh",
    text: "Checked In (WFH)",
    filterBy: ATTENDANCE_FILTERS.checked_in_wfh,
    iconColor: "primary",
    iconComponent: "home_work",
  },
  {
    name: "total_on_break",
    text: "On break",
    filterBy: ATTENDANCE_FILTERS.on_break,
    iconColor: "warning",
    iconComponent: "cookie",
  },
  {
    name: "total_checked_out",
    text: "Checked Out",
    filterBy: ATTENDANCE_FILTERS.checked_out,
    iconColor: "dark",
    iconComponent: "logout",
  },
  {
    name: "total_absent",
    text: "Absent",
    filterBy: ATTENDANCE_FILTERS.absent,
    iconColor: "error",
    iconComponent: "event_busy",
  },
];

export const USER_REPORT_CARDS = [
  {
    name: "total_hours",
    text: "Total Hours",
    iconColor: "info",
    iconComponent: "alarm_clock",
  },
  {
    name: "total_attendance_hours",
    text: "Total Attendance",
    iconColor: "success",
    iconComponent: "login",
  },
  {
    name: "office_duration_hours",
    text: "Office Duration",
    iconColor: "primary",
    iconComponent: "work",
  },
  {
    name: "breaks_duration",
    text: "Break Duration",
    iconColor: "warning",
    iconComponent: "cookie",
  },
  {
    name: "wfh_duration_hours",
    text: "Work From Home Duration",
    iconColor: "dark",
    iconComponent: "home",
  },
];

export const THEME_COLORS = {
  primary: "#18C1E8",
  secondary: "#9825B9",
  secondary_light: "#DADADA40",
  black: "#000000",
  white: "#ffffff",
};

export const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    fontSize: 12,
    minHeight: "15px",
    height: "35px",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: THEME_COLORS.black,
  }),
  menu: (base) => ({ ...base, zIndex: 9 }),
  option: (provided, state) => {
    const selectedOptionColor = THEME_COLORS.white;
    const selectedOptionBackgroundColor = THEME_COLORS.primary;
    const selectedOptionColorLight = THEME_COLORS.black;
    const selectedOptionBackgroundColorLight = THEME_COLORS.secondary_light;
    return {
      ...provided,
      height: 35,
      fontSize: 12,
      color: state.isSelected && selectedOptionColor,
      backgroundColor: state.isSelected && selectedOptionBackgroundColor,
      "&:hover": {
        color: state.isSelected ? selectedOptionColor : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
      "&:focus": {
        color: state.isSelected ? selectedOptionColor : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
      "&:active": {
        color: state.isSelected ? selectedOptionColor : selectedOptionColorLight,
        backgroundColor: state.isSelected
          ? selectedOptionBackgroundColor
          : selectedOptionBackgroundColorLight,
      },
    };
  },
};
