// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
// Soft UI Dashboard React examples
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
// Dashboard layout components
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// @mui material components
import Card from "@mui/material/Card";
import Table from "examples/Tables/Table";
// Data
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { CircularProgress, IconButton, Modal } from "@mui/material";
import ErrorAlert from "components/ErrorAlert";
import SuiBadge from "components/SuiBadge";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { ATTENDANCE_FILTERS, NOTIFICATION_MESSAGES, TIME_FORMAT } from "constants";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  archiveUserReq,
  getDashboardCardsReq,
  getDashboardTableReq,
  notifyUserToCheckIn,
} from "services/api";
import Pagination from "utils/Pagination";
import Author from "./components/TableAuthor";
import { DASHBOARD_CARDS, MODAL_STYLE } from "./dashboard.constants";

function Dashboard() {
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [dashboardCards, setDashboardCards] = useState({
    total_on_break: 0,
    total_absent: 0,
    total_checked_in_office: 0,
    total_checked_in_wfh: 0,
    total_checked_out: 0,
    total_users: 0,
  });

  const [filterBy, setFilterBy] = useState(ATTENDANCE_FILTERS.all);
  const [dashboardTable, setDashboardTable] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 10;
  const columns = [
    { name: "employees", align: "left" },
    { name: "checked_in", align: "center" },
    { name: "checked_out", align: "center" },
    { name: "working_from", align: "center" },
    { name: "status", align: "center" },
    { name: "action", align: "center" },
  ];
  const [attendance_rows, setattendance_rows] = useState([]);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [userToArchive, setUserToArchive] = useState({
    name: null,
    id: null,
  });
  const [userToNotify, setUserToNotify] = useState({
    name: null,
    id: null,
    message: "",
  });

  // Store
  const {
    auth: { admin, team, channel },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  //API Calls
  const getDashboardCards = async () => {
    setIsCardLoading(true);
    try {
      const admin_id = admin.id;
      const res = await getDashboardCardsReq({
        admin_id,
        team_id: team.id,
        channel_id: channel.id,
      });
      setDashboardCards(res.data);
    } catch (err) {
      setErrorMessage(err.response?.data.message);
    }
    setIsCardLoading(false);
  };
  const getDashboardTable = async (filterBy) => {
    setIsLoading(true);
    setFilterBy(filterBy);
    getDashboardCards();
    try {
      const admin_id = admin.id;
      let params = {
        search: searchTerm,
        pageNumber: currentPage,
        pageLimit: pageLimit,
        filter_by: filterBy,
      };
      const res = await getDashboardTableReq({
        admin_id,
        team_id: team.id,
        channel_id: channel.id,
        params,
      });
      const { attendances, total } = res.data;
      setTotalCount(total);
      setDashboardTable(attendances);
    } catch (err) {
      setErrorMessage(err.response?.data.message);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getDashboardCards();
    const delayDebounceFn = searchTerm
      ? (setIsLoading(true),
        // setCurrentPage(1),
        setTimeout(() => {
          getDashboardTable(ATTENDANCE_FILTERS.all);
        }, 1000))
      : getDashboardTable(filterBy);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, currentPage]);
  useEffect(() => {
    setRows();
  }, [dashboardTable, currentPage, searchTerm]);
  function setRows() {
    let temp_array = [];
    dashboardTable &&
      dashboardTable.forEach((data) => {
        console.log("data", data);
        let color = "info",
          badgeContent = "-";
        if (data.is_absent) {
          color = "error";
          badgeContent = "Absent";
        } else if (data.on_break) {
          color = "warning";
          badgeContent = "On Break";
        } else if (data.checked_out) {
          color = "dark";
          badgeContent = "Checked Out";
        } else if (data.checked_out === null) {
          if (data.working_from === "Office") {
            color = "success";
            badgeContent = "Checked In";
          } else if (data.working_from === "WFH") {
            color = "primary";
            badgeContent = "Checked In";
          }
        }
        temp_array.push({
          rowOnClick: () =>
            navigate(`/reports`, {
              state: { value: data.id, label: `${data.name} (${data.user_name})` },
            }),
          employees: <Author image={data.image_url} name={data.name} email={data.user_name} />,
          checked_in: (
            <SuiTypography variant="caption" color="secondary" fontWeight="medium">
              {moment.unix(data.checked_in).format(TIME_FORMAT)}
              <br />
              {moment.unix(data.checked_in).fromNow()}
            </SuiTypography>
          ),
          checked_out: (
            <SuiTypography variant="caption" color="secondary" fontWeight="medium">
              {data.checked_out ? moment.unix(data.checked_out).format(TIME_FORMAT) : "-"}
              <br />
              {data.checked_out && moment.unix(data.checked_out).fromNow()}
            </SuiTypography>
          ),
          working_from: data.is_absent ? (
            "N/A"
          ) : (
            <Icon
              fontSize="small"
              color={data.working_from === "Office" ? "success" : "primary"}
              className="cursor-pointer"
            >
              {data.working_from === "Office" ? "business" : "home_work"}
            </Icon>
          ),
          status: (
            <SuiBadge
              variant="gradient"
              badgeContent={badgeContent}
              color={color}
              size="xs"
              container
            />
          ),
          action: (
            <>
              <IconButton
                color="secondary"
                component="button"
                onClick={(e) => onClickArchive(e, data.id, data.name)}
              >
                <PersonRemoveIcon />
              </IconButton>
              {data.is_absent && (
                <IconButton
                  color="secondary"
                  component="button"
                  onClick={(e) => onClickNotify(e, data.id)}
                >
                  <NotificationsActiveIcon />
                </IconButton>
              )}
            </>
          ),
        });
      });
    setattendance_rows(temp_array);
  }

  async function onClickArchive(event, userId, name) {
    event.stopPropagation();
    setUserToArchive({ name: name, id: userId });
    setShowArchiveModal(true);
  }
  const onClickNotify = (event, userId) => {
    event.stopPropagation();
    setUserToNotify((currentState) => ({ ...currentState, id: userId }));
    setShowNotifyModal(true);
  };
  const notifyUser = async () => {
    try {
      const response = await notifyUserToCheckIn({
        team_id: team.id,
        channel_id: channel.id,
        admin_id: admin.id,
        user_id: userToNotify.id,
        message: userToNotify.message,
      });
      if (response.data && response.data.success) {
        setSuccessMessage(response.data.message);
      } else {
        setErrorMessage(response.data.message);
      }
      setUserToNotify({
        id: null,
        name: null,
        message: null,
      });
      setShowNotifyModal(false);
    } catch (error) {
      setErrorMessage("Something Went Wrong!!");
      console.log(error);
    }
  };
  async function archiveUser() {
    setIsCardLoading(true);
    try {
      const admin_id = admin.id;
      const res = await archiveUserReq({
        admin_id,
        team_id: team.id,
        channel_id: channel.id,
        user_slack_id: userToArchive.id,
      });

      setShowArchiveModal(false);
      setSuccessMessage(res.data.message);
      getDashboardTable(ATTENDANCE_FILTERS.all);
    } catch (err) {
      setErrorMessage(err.response?.data.message);
    }
    setIsCardLoading(false);
  }
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {errorMessage && (
          <ErrorAlert
            color="error"
            message={errorMessage}
            clearError={() => setErrorMessage(null)}
          />
        )}
        {successMessage && (
          <ErrorAlert
            color="success"
            message={successMessage}
            clearError={() => setSuccessMessage(null)}
          />
        )}
        <SuiBox py={3}>
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              {DASHBOARD_CARDS.map((dashboardCard, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} xl={4}>
                    <div
                      onClick={() => {
                        setCurrentPage(1);
                        getDashboardTable(dashboardCard.filterBy);
                      }}
                      className="cursor-pointer user-select-none dashboard-cards"
                    >
                      <MiniStatisticsCard
                        title={{ text: `${dashboardCard.text}` }}
                        count={
                          isCardLoading ? (
                            <CircularProgress color="dark" size={18} />
                          ) : (
                            dashboardCards[dashboardCard.name]
                          )
                        }
                        icon={{
                          color: `${dashboardCard.iconColor}`,
                          component: `${dashboardCard.iconComponent}`,
                        }}
                        isActive={filterBy === dashboardCard.filterBy}
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </SuiBox>
          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography
                  variant="h6"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span>
                    {team.name} ~ {channel.name}
                  </span>
                  <Icon
                    fontSize="small"
                    color="inherit"
                    className="cursor-pointer w-30"
                    onClick={() => {
                      setCurrentPage(1);
                      getDashboardTable(filterBy);
                    }}
                  >
                    {"cached"}
                  </Icon>
                </SuiTypography>
                <SuiBox display="flex" alignItems="center">
                  <SuiInput
                    placeholder="Search"
                    icon={{ component: "search", direction: "left" }}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </SuiBox>
              </SuiBox>
              <SuiBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {isLoading ? (
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <CircularProgress color="dark" size={30} />
                  </SuiBox>
                ) : !isEmpty(dashboardTable) ? (
                  <Table columns={columns} rows={attendance_rows} />
                ) : (
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <Icon fontSize="large" color="error" className="cursor-pointer">
                      {"report"}
                    </Icon>
                    <SuiTypography
                      component="span"
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      color={"dark"}
                      opacity={0.5}
                      m={3}
                      sx={{ lineHeight: 0 }}
                    >
                      No records found
                    </SuiTypography>
                  </SuiBox>
                )}
              </SuiBox>
            </Card>
          </SuiBox>
          {!isLoading && (
            <SuiBox mb={3}>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageLimit}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </SuiBox>
          )}
          {/* <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <BuildByDevelopers />
              </Grid>
              <Grid item xs={12} lg={5}>
                <WorkWithTheRockets />
              </Grid>
            </Grid>
          </SuiBox> */}
          {/* <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <ReportsBarChart
                  title="active users"
                  description={
                    <>
                      (<strong>+23%</strong>) than last week
                    </>
                  }
                  chart={chart}
                  items={items}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <GradientLineChart
                  title="Sales Overview"
                  description={
                    <SuiBox display="flex" alignItems="center">
                      <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                        <Icon className="font-bold">arrow_upward</Icon>
                      </SuiBox>
                      <SuiTypography variant="button" color="text" fontWeight="medium">
                        4% more{" "}
                        <SuiTypography variant="button" color="text" fontWeight="regular">
                          in 2021
                        </SuiTypography>
                      </SuiTypography>
                    </SuiBox>
                  }
                  height="20.25rem"
                  chart={gradientLineChartData}
                />
              </Grid>
            </Grid>
          </SuiBox> */}
          {/* <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrderOverview />
            </Grid>
          </Grid> */}
        </SuiBox>
        <Modal
          open={showArchiveModal}
          onClose={() => setShowArchiveModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SuiBox sx={MODAL_STYLE} textAlign="center">
            <h5>Are you sure to archive {userToArchive.name} from Hazri?</h5>
            <SuiBox pt={2}>
              <SuiButton
                onClick={() => archiveUser()}
                size="small"
                variant="outlined"
                color="error"
                className="me-5"
              >
                Yes
              </SuiButton>
              <SuiButton
                onClick={() => setShowArchiveModal(false)}
                size="small"
                variant="outlined"
                color="success"
                className="ms-5"
              >
                No
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </Modal>
        <Modal
          open={showNotifyModal}
          onClose={() => setShowArchiveModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SuiBox sx={MODAL_STYLE} textAlign="center">
            <SuiTypography variant="h6" color="black" fontWeight="bold">
              {"Check-In Reminders"}
            </SuiTypography>
            <div style={{ height: "10px" }}></div>
            <SuiInput
              placeholder={`Please select any message or type a custom message`}
              multiline
              rows={3}
              cols={4}
              value={userToNotify.message}
              onChange={(e) =>
                setUserToNotify((currentState) => ({
                  ...currentState,
                  message: e.target.value,
                }))
              }
            />
            <SuiBox pt={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <SuiButton
                    onClick={() => {
                      const messageId = Math.floor(Math.random() * NOTIFICATION_MESSAGES.length);

                      setUserToNotify((currentState) => ({
                        ...currentState,
                        message: NOTIFICATION_MESSAGES[messageId],
                      }));
                    }}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className="me-5 w-100"
                  >
                    Generate
                  </SuiButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SuiButton
                    onClick={() => notifyUser()}
                    disabled={isEmpty(userToNotify.message)}
                    size="small"
                    variant="outlined"
                    color="success"
                    className="me-5 ms-5 w-100"
                  >
                    Send
                  </SuiButton>
                </Grid>
                <Grid item xs={12} md={4}>
                  <SuiButton
                    onClick={() => {
                      setUserToNotify({
                        id: null,
                        name: null,
                        message: null,
                      });
                      setShowNotifyModal(false);
                    }}
                    size="small"
                    color="error"
                    variant="outlined"
                    className="ms-5 w-100"
                  >
                    Cancel
                  </SuiButton>
                </Grid>
              </Grid>
            </SuiBox>
          </SuiBox>
        </Modal>
        <Footer />
      </DashboardLayout>
    </>
  );
}
export default Dashboard;
