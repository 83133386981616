import { createSlice } from "@reduxjs/toolkit";
import { signIn } from "./auth.thunk";

const initialState = {
  error: null,
  success: null,
  loading: false,
  isLoggedIn: false,
  token: null,
  admin: null,
  team: null,
  channel: null,
  admin_teams_channels: [],
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetMessageState: (state) => {
      state.error = null;
      state.success = null;
    },
    signout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
    },
    updateDefaultTeamChannel: (state, { payload }) => {
      state.team = payload.team;
      state.channel = payload.channel;
    },
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.error = null;
      state.success = true;
      state.token = payload.token;
      state.isLoggedIn = true;
      state.loading = false;
      state.admin = payload.admin;
      state.team = payload.team;
      state.channel = payload.channel;
      state.admin_teams_channels = payload.admin_teams_channels;
    },
    [signIn.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
      state.success = null;
    },
  },
});

export const { resetMessageState, signout, updateDefaultTeamChannel } = AuthSlice.actions;

export default AuthSlice.reducer;
