import * as yup from "yup";

export const signInSchema = yup.object().shape({
    email: yup
        .string().required("Email is required"),
    password: yup
        .string()
        .min(8, "Password Cannot Be Less Than 8 Characters")
        .max(30, "Password Cannot Exceed 30 Characters")
        .required("Password is required"),
});
