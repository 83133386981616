import axios from "axios";

export const getDashboardCardsReq = ({ admin_id, team_id, channel_id }) => {
  return axios.get(`/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/dashboard/cards`);
};

export const getDashboardTableReq = ({ admin_id, team_id, channel_id, params }) => {
  return axios.get(
    `/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/dashboard/attendances`,
    { params }
  );
};

export const getUserAttendanceReportReq = ({
  admin_id,
  team_id,
  channel_id,
  user_slack_id,
  body,
}) => {
  return axios.post(
    `/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/users/${user_slack_id}/reports`,
    body
  );
};

export const getUsersReq = ({ admin_id, team_id, channel_id }) => {
  return axios.get(`/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/users`);
};
export const notifyUserToCheckIn = ({ admin_id, user_id, channel_id, team_id, message }) => {
  return axios.post(
    `/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/users/${user_id}/notifyToCheckIn`,
    { message }
  );
};
export const archiveUserReq = ({ admin_id, team_id, channel_id, user_slack_id }) => {
  return axios.put(
    `/admins/${admin_id}/teams/${team_id}/channels/${channel_id}/users/${user_slack_id}/archive`
  );
};
