import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import appConfig from "../../config";

export const signIn = createAsyncThunk("SIGN_IN", async (body) => {
  try {
    const { data } = await axios.post(`${appConfig.baseUrl}/admins/sign_in`, body);
    return data;
  } catch (err) {
    throw err.response.data;
  }
});
