import React from "react";
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ErrorAlert from "components/ErrorAlert";
import CustomSelect from "components/CustomSelect";
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import moment from "moment";
import { getUsersReq } from "services/api";
import { isEmpty } from "lodash";
import { getUserAttendanceReportReq } from "services/api";
import TimelineItem from "examples/Timeline/TimelineItem";
import { CHECKED_IN_TYPES } from "constants";
import { customSelectStyles } from "./reports.constants";
import { useLocation } from "react-router-dom";
import { TIME_FORMAT } from "constants";
import { toHoursAndMinutes } from "utils/DateAndTime";
import { minsDiff } from "utils/DateAndTime";

const rangeValues = [
  { label: "Last 7 days", value: 7 },
  { label: "Last 14 days", value: 14 },
  { label: "Last 21 days", value: 21 },
  { label: "Last 30 days", value: 30 },
];

function Reports() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedRange, setSelectedRange] = useState(rangeValues[0]);
  const [attendances, setAttendances] = useState([]);
  const { state } = useLocation();

  // Store
  const {
    auth: { admin, team, channel },
  } = useSelector((state) => state);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const admin_id = admin.id;
      const { data } = await getUsersReq({
        admin_id,
        team_id: team.id,
        channel_id: channel.id,
      });
      setUsers(data);
      if (state.value) {
        setSelectedUser(state);
        generateReport(state);
      }
    } catch (err) {
      setErrorMessage(err.response?.data.message);
    }
    setIsLoading(false);
  };

  const generateReport = async (user) => {
    if ((!isEmpty(selectedUser) || !isEmpty(user)) && !isEmpty(selectedRange)) {
      setIsReportLoading(true);
      try {
        const body = {
          selectedRange: selectedRange.value,
        };
        const admin_id = admin.id;
        const { data } = await getUserAttendanceReportReq({
          admin_id,
          team_id: team.id,
          channel_id: channel.id,
          user_slack_id: selectedUser.value || user.value,
          body,
        });
        setAttendances(data);
      } catch (err) {
        setErrorMessage(err.response?.data.message);
      }
      setIsReportLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {errorMessage && (
          <ErrorAlert
            color="error"
            message={errorMessage}
            clearError={() => setErrorMessage(null)}
          />
        )}
        <SuiBox py={3}>
          <SuiBox display="flex" justifyContent="start" alignItems="center">
            <SuiBox pr={3} className="w-340">
              {isLoading ? (
                <CircularProgress color="dark" size={18} />
              ) : (
                <CustomSelect
                  isSearchable={true}
                  customStyles={customSelectStyles}
                  options={users}
                  defaultValue={state?.value && selectedUser}
                  value={selectedUser}
                  placeholder="Select Employee"
                  onChange={(data) => setSelectedUser(data)}
                />
              )}
            </SuiBox>
            <SuiBox pr={3}>
              <CustomSelect
                customStyles={customSelectStyles}
                options={rangeValues}
                defaultValue={selectedRange}
                placeholder="Select Days"
                onChange={(data) => setSelectedRange(data)}
              />
            </SuiBox>
            <SuiBox>
              <SuiButton variant="gradient" color="secondary" fullWidth onClick={generateReport}>
                Generate Report
                <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox pb={3}>
          <Grid container spacing={3}>
            {isReportLoading ? (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="h-100">
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <CircularProgress color="dark" size={30} />
                  </SuiBox>
                </Card>
              </Grid>
            ) : attendances.length > 0 ? (
              attendances.map((attendance, index) => {
                let start = 0;
                let duration = 0;
                return (
                  <Grid item xs={12} md={6} lg={4} key={`main-${index}`}>
                    <Card className="h-100">
                      <SuiBox pt={3} px={3}>
                        <SuiTypography variant="h6" fontWeight="medium">
                          {moment(attendance.day).format("LL")}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox p={2} className="report-card-fix-height">
                        {attendance.records.map((record, recordIndex) => {
                          if (recordIndex === 0 || record.from) {
                            start = record.from;
                            duration = minsDiff(record.from, start);
                          } else {
                            if (record.break_from) {
                              duration += minsDiff(record.break_from, start);
                              start = record.break_from;
                            }
                            if (record.break_to) {
                              start = record.break_to;
                            }
                            if (record.to) {
                              duration += minsDiff(record.to, start);
                              start = record.to;
                            }
                          }

                          return (
                            <React.Fragment key={`record-${recordIndex}`}>
                              {record.from && (
                                <TimelineItem
                                  color={`${
                                    record.type === CHECKED_IN_TYPES.wfh ? "primary" : "success"
                                  }`}
                                  icon={`${
                                    record.type === CHECKED_IN_TYPES.wfh ? "home_work" : "business"
                                  }`}
                                  title={`${recordIndex ? "Checked-In-Again" : "Checked-In"} (${
                                    record.type
                                  })`}
                                  dateTime={moment.unix(record.from).format(TIME_FORMAT)}
                                  comment={record.from_comment}
                                />
                              )}
                              {record.break_from && (
                                <TimelineItem
                                  color="warning"
                                  icon="cookie"
                                  title={`Break`}
                                  dateTime={moment.unix(record.break_from).format(TIME_FORMAT)}
                                  comment={record.break_from_comment}
                                />
                              )}
                              {record.break_to && (
                                <TimelineItem
                                  color="secondary"
                                  icon="reply"
                                  title={`Back`}
                                  dateTime={moment.unix(record.break_to).format(TIME_FORMAT)}
                                  comment={record.break_to_comment}
                                />
                              )}
                              {record.to && (
                                <TimelineItem
                                  color="dark"
                                  icon="logout"
                                  title={`Checked-Out (${record.type})`}
                                  dateTime={moment.unix(record.to).format(TIME_FORMAT)}
                                  comment={record.to_comment}
                                  description={`Duration: ${toHoursAndMinutes(duration)}`}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </SuiBox>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} md={12} lg={12}>
                <Card className="h-100">
                  <SuiBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={3}
                  >
                    <Icon fontSize="large" color="error" className="cursor-pointer">
                      {"report"}
                    </Icon>
                    <SuiTypography
                      component="span"
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      color={"dark"}
                      opacity={0.5}
                      m={3}
                      sx={{ lineHeight: 0 }}
                    >
                      No records found
                    </SuiTypography>
                  </SuiBox>
                </Card>
              </Grid>
            )}
          </Grid>
        </SuiBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
export default Reports;
