import { isNil } from "lodash";

const moment = require("moment");

export const minsDiff = (end, start) => {
  if (isNil(start) && isNil(end)) {
    return 0;
  } else {
    return Math.ceil(moment.duration(moment.unix(end).diff(moment.unix(start))).asMinutes());
  }
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${hours === 0 && minutes === 0 ? "N/A" : `${hours}h ${minutes}m`}`;
};
