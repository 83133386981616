/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import moment from "moment";

function Bill({
  noGutter,
  createdAt,
  from,
  fromComment,
  to,
  toComment,
  type,
  totalDuration,
  breaks,
}) {

  const getBreakDuration = () => {
    let breakHours  = 0
    for (let attendanceBreak of breaks){
      breakHours += moment.unix(attendanceBreak.to).diff(moment.unix(attendanceBreak.from), "hours");
    }
   return breakHours
  }
  return (
    <>
      <SuiBox bgColor="grey-100" p={3} mb={noGutter ? 0 : 1} mt={2} borderRadius="lg">
        {" "}
        <SuiBox mb={1} lineHeight={0}>
          <SuiTypography variant="caption" color="text">
            Date:&nbsp;&nbsp;&nbsp;
            <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {moment.unix(createdAt).format("MMMM Do YYYY")}
            </SuiTypography>
          </SuiTypography>
        </SuiBox>
        <SuiBox
          component="li"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          bgColor="grey-100"
          borderRadius="lg"
          p={1}
          mb={noGutter ? 0 : 1}
          mt={1}
        >
          <SuiBox width="100%" display="flex" flexDirection="column">
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                From:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {moment.unix(from).format("h:mm:ss a")}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                From Comment:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium">
                  {fromComment ? fromComment : "N/A"}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                To:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium">
                  {moment.unix(to).format("h:mm:ss a")}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                To Comment:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium">
                  {toComment ? toComment : "N/A"}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
          <SuiBox width="100%" display="flex" flexDirection="column">
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                Type:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {type}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                Breaks:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                  {breaks.length}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                Total Break Duration:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium">
                  {getBreakDuration()}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
            <SuiBox mb={1} lineHeight={0}>
              <SuiTypography variant="caption" color="text">
                Total hours:&nbsp;&nbsp;&nbsp;
                <SuiTypography variant="caption" fontWeight="medium">
                  {totalDuration}
                </SuiTypography>
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </>
  );
}

// Setting default values for the props of Bill
Bill.defaultProps = {
  noGutter: false,
};

export default Bill;
