import React from "react";
import Select from "react-select";

const CustomSelect = ({
  defaultValue,
  placeholder,
  options,
  onChange,
  customStyles,
  isSearchable,
}) => {
  return (
    <Select
      placeholder={placeholder}
      styles={customStyles}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      isSearchable={isSearchable ? isSearchable : false}
    />
  );
};

export default CustomSelect;
