// react-router-dom components
import { Link } from "react-router-dom";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/haazri-logo.jpg";

// Custom Imports
import { useForm } from "react-hook-form";
import { signInSchema } from "./sign-in.constants";
import { yupResolver } from "@hookform/resolvers/yup";
import ControlledInputField from "components/ControlledInputField";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { signIn } from "../auth.thunk";
import { useEffect } from "react";
import ErrorAlert from "components/ErrorAlert";
import { resetMessageState } from "../auth.reducer";
import { CircularProgress } from "@mui/material";

function SignIn() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { formState: { errors }, control, handleSubmit } = useForm({
    resolver: yupResolver(signInSchema),
  });

  // Store
  const { loading, error } = useSelector((state) => state.auth)

  function onSubmit(data) {
    dispatch(signIn(data));
  }

  useEffect(() => {
    dispatch(resetMessageState());
  }, [pathname]);

  function clearError() {
    dispatch(resetMessageState());
  }

  return (
    <CoverLayout
      title="Haazri Admin Portal"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      {error && <ErrorAlert color="error" message={error} clearError={clearError} />}
      <SuiBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
        <SuiBox>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SuiTypography>
          </SuiBox>
          <ControlledInputField type="email" placeholder="Enter Email" name="email" control={control} errors={errors} />
        </SuiBox>
        <SuiBox mb={2}>
          <SuiBox mb={1} ml={0.5}>
            <SuiTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SuiTypography>
          </SuiBox>
          <ControlledInputField type="password" placeholder="Enter Password" name="password" control={control} errors={errors} />
        </SuiBox>
        <SuiBox mt={4} mb={1}>
          <SuiButton variant="gradient" color="info" fullWidth type="submit" disabled={loading}>
            {loading ? <CircularProgress color="white" size={17} /> : "sign in"}
          </SuiButton>
        </SuiBox>
        {/* <SuiBox mt={3} textAlign="center">
          <SuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SuiTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SuiTypography>
          </SuiTypography>
        </SuiBox> */}
      </SuiBox>
    </CoverLayout>
  );
}

export default SignIn;
